import PTBRCommon from "./assets/locales/pt-BR/common.json";
import PTBRGlossary from "./assets/locales/pt-BR/glossary.json";
import PTBRcards from "./assets/locales/pt-BR/cards.json";
import PTBRdriver from "./assets/locales/pt-BR/driver.json";
import PTBRnavheader from "./assets/locales/pt-BR/navheader.json";
import PTBRtransaction from "./assets/locales/pt-BR/transaction.json";
import PTBRvalidation from "./assets/locales/pt-BR/validation.json";
import PTBRvehicle from "./assets/locales/pt-BR/vehicle.json";
import PTBRCreditManagement from "./assets/locales/pt-BR/credit-management.json";
import PTBRGroupRules from "./assets/locales/pt-BR/group-rules.json";
import PTBRlogin from "./assets/locales/pt-BR/login.json";
import PTBROptions from "./assets/locales/pt-BR/options.json";
import PTBRScheduler from "./assets/locales/pt-BR/scheduler.json";
import PTBRReport from "./assets/locales/pt-BR/report.json";
import PTBRImporter from "./assets/locales/pt-BR/importer.json";

import ESCOCommon from "./assets/locales/es-CO/common.json";
import ESCOGlossary from "./assets/locales/es-CO/glossary.json";
import ESCOcards from "./assets/locales/es-CO/cards.json";
import ESCOdriver from "./assets/locales/es-CO/driver.json";
import ESCOnavheader from "./assets/locales/es-CO/navheader.json";
import ESCOtransaction from "./assets/locales/es-CO/transaction.json";
import ESCOvalidation from "./assets/locales/es-CO/validation.json";
import ESCOvehicle from "./assets/locales/es-CO/vehicle.json";
import ESCOCreditManagement from "./assets/locales/es-CO/credit-management.json";
import ESCOGroupRules from "./assets/locales/es-CO/group-rules.json";
import ESCOlogin from "./assets/locales/es-CO/login.json";
import ESCOOptions from "./assets/locales/es-CO/options.json";
import ESCOScheduler from "./assets/locales/es-CO/scheduler.json";
import ESCOImporter from "./assets/locales/es-CO/importer.json";

import ESMXCommon from "./assets/locales/es-MX/common.json";
import ESMXGlossary from "./assets/locales/es-MX/glossary.json";
import ESMXcards from "./assets/locales/es-MX/cards.json";
import ESMXdriver from "./assets/locales/es-MX/driver.json";
import ESMXnavheader from "./assets/locales/es-MX/navheader.json";
import ESMXtransaction from "./assets/locales/es-MX/transaction.json";
import ESMXvalidation from "./assets/locales/es-MX/validation.json";
import ESMXvehicle from "./assets/locales/es-MX/vehicle.json";
import ESMXCreditManagement from "./assets/locales/es-MX/credit-management.json";
import ESMXGroupRules from "./assets/locales/es-MX/group-rules.json";
import ESMXlogin from "./assets/locales/es-MX/login.json";
import ESMXOptions from "./assets/locales/es-MX/options.json";
import ESMXScheduler from "./assets/locales/es-MX/scheduler.json";
import ESMXImporter from "./assets/locales/es-MX/importer.json";

import ENUSCommon from "./assets/locales/en-US/common.json";
import ENUSGlossary from "./assets/locales/en-US/glossary.json";
import ENUScards from "./assets/locales/en-US/cards.json";
import ENUSdriver from "./assets/locales/en-US/driver.json";
import ENUSnavheader from "./assets/locales/en-US/navheader.json";
import ENUStransaction from "./assets/locales/en-US/transaction.json";
import ENUSvalidation from "./assets/locales/en-US/validation.json";
import ENUSvehicle from "./assets/locales/en-US/vehicle.json";
import ENUSCreditManagement from "./assets/locales/en-US/credit-management.json";
import ENUSGroupRules from "./assets/locales/en-US/group-rules.json";
import ENUSlogin from "./assets/locales/en-US/login.json";
import ENUSScheduler from "./assets/locales/en-US/scheduler.json";

import FRFRCommon from "./assets/locales/fr-FR/common.json";
import FRFRGlossary from "./assets/locales/fr-FR/glossary.json";
import FRFRcards from "./assets/locales/fr-FR/cards.json";
import FRFRdriver from "./assets/locales/fr-FR/driver.json";
import FRFRnavheader from "./assets/locales/fr-FR/navheader.json";
import FRFRtransaction from "./assets/locales/fr-FR/transaction.json";
import FRFRvalidation from "./assets/locales/fr-FR/validation.json";
import FRFRvehicle from "./assets/locales/fr-FR/vehicle.json";
import FRFRCreditManagement from "./assets/locales/fr-FR/credit-management.json";
import FRFRGroupRules from "./assets/locales/fr-FR/group-rules.json";
import FRFRlogin from "./assets/locales/fr-FR/login.json";
import FRFRScheduler from "./assets/locales/fr-FR/scheduler.json";

export const ptBRi18namespaces = {
    common: {...PTBRGlossary, ...PTBRCommon},
    cards: PTBRcards,
    driver: PTBRdriver,
    navheader: PTBRnavheader,
    transaction: PTBRtransaction,
    validation: PTBRvalidation,
    vehicle: PTBRvehicle,
    login: PTBRlogin,
    creditManagement: PTBRCreditManagement,
    grouprules: PTBRGroupRules,
    options: PTBROptions,
    scheduler: PTBRScheduler,
    report: PTBRReport,
    importer: PTBRImporter,
};

export const esCOi18namespaces = {
    common: {...ESCOGlossary, ...ESCOCommon},
    cards: ESCOcards,
    driver: ESCOdriver,
    navheader: ESCOnavheader,
    transaction: ESCOtransaction,
    validation: ESCOvalidation,
    vehicle: ESCOvehicle,
    login: ESCOlogin,
    creditManagement: ESCOCreditManagement,
    grouprules: ESCOGroupRules,
    options: ESCOOptions,
    scheduler: ESCOScheduler,
    importer: ESCOImporter,
};

export const esMXi18namespaces = {
    common: {...ESMXGlossary, ...ESMXCommon},
    cards: ESMXcards,
    driver: ESMXdriver,
    navheader: ESMXnavheader,
    transaction: ESMXtransaction,
    validation: ESMXvalidation,
    vehicle: ESMXvehicle,
    login: ESMXlogin,
    creditManagement: ESMXCreditManagement,
    grouprules: ESMXGroupRules,
    options: ESMXOptions,
    scheduler: ESMXScheduler,
    importer: ESMXImporter,
};
export const enUSi18namespaces = {
    common: {...ENUSGlossary, ...ENUSCommon},
    cards: ENUScards,
    driver: ENUSdriver,
    navheader: ENUSnavheader,
    transaction: ENUStransaction,
    validation: ENUSvalidation,
    vehicle: ENUSvehicle,
    login: ENUSlogin,
    creditManagement: ENUSCreditManagement,
    grouprules: ENUSGroupRules,
    scheduler: ENUSScheduler,
};
export const frFRi18namespaces = {
    common: {...FRFRGlossary, ...FRFRCommon},
    cards: FRFRcards,
    driver: FRFRdriver,
    navheader: FRFRnavheader,
    transaction: FRFRtransaction,
    validation: FRFRvalidation,
    vehicle: FRFRvehicle,
    login: FRFRlogin,
    creditManagement: FRFRCreditManagement,
    grouprules: FRFRGroupRules,
    scheduler: FRFRScheduler,
};
