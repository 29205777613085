import {
  ptBR as dateFnsptBR,
  es as dateFnses,
  enUS as dateFnsenUS,
  fr as dateFnsfr,
} from "date-fns/locale";
export const LOCALE_STORAGE = "LOCALE";
export const LOCALE_PTBR = "pt-BR";
export const LOCALE_ESCO = "es-CO";
export const LOCALE_ESMX = "es-MX";
export const LOCALE_ENUS = "en-US";
export const LOCALE_FRFR = "fr-FR";

const currencyDefaultOptions = {
  style: "currency",
};

const unitDefaultOptions = {
  style: "unit",
  unitDisplay: "long",
};

const defaultUnits = {
  liters: {
    ...unitDefaultOptions,
    unit: "liter",
  },
  km: {
    ...unitDefaultOptions,
    unit: "kilometer",
  },
};

const intlOptionUS = {
  locale: LOCALE_ENUS,
  dateFns: dateFnsenUS,
  currency: {
    ...currencyDefaultOptions,
    currency: "USD",
  },
  ...defaultUnits,
};

const intlOptionFR = {
  locale: LOCALE_FRFR,
  dateFns: dateFnsfr,
  currency: {
    ...currencyDefaultOptions,
    currency: "EUR",
  },
  ...defaultUnits,
};
const intlOptionMX = {
  locale: LOCALE_ESMX,
  dateFns: dateFnses,
  currency: {
    ...currencyDefaultOptions,
    currency: "MXN",
  },
  ...defaultUnits,
};
const intlOptionCO = {
  locale: LOCALE_ESCO,
  dateFns: dateFnses,
  liters: {
    ...unitDefaultOptions,
    unit: "gallon",
  },
  km: {
    ...unitDefaultOptions,
    unit: "mile",
  },
  currency: {
    ...currencyDefaultOptions,
    currency: "COP",
  },
};
const intlOptionBR = {
  locale: LOCALE_PTBR,
  dateFns: dateFnsptBR,
  currency: {
    ...currencyDefaultOptions,
    currency: "BRL",
  },
  ...defaultUnits,
};

export const Locales = {
  [LOCALE_PTBR]: intlOptionBR,
  [LOCALE_ESCO]: intlOptionCO,
  [LOCALE_ESMX]: intlOptionMX,
  [LOCALE_ENUS]: intlOptionUS,
  [LOCALE_FRFR]: intlOptionFR,
};

const countryIdForLocales = [
  null,
  LOCALE_PTBR,
  LOCALE_ESCO,
  LOCALE_ESMX,
  LOCALE_ENUS,
  LOCALE_FRFR,
];

export function getLocaleByCountryId(id) {
  return countryIdForLocales[id] ?? LOCALE_PTBR;
}

export function setDefaultLocale(locale = LOCALE_PTBR) {
  if (!localStorage.getItem(LOCALE_STORAGE)) {
    setLocale(locale);
  }
}

export function setLocale(locale) {
  localStorage.setItem(LOCALE_STORAGE, locale);
}

export function getLocale() {
  return localStorage.getItem(LOCALE_STORAGE) ?? LOCALE_PTBR;
}
