import {
    ptBRi18namespaces,
    esCOi18namespaces,
    esMXi18namespaces,
    enUSi18namespaces,
    frFRi18namespaces,
} from "./Translations";
import {getLocale, LOCALE_ENUS, LOCALE_ESCO, LOCALE_ESMX, LOCALE_FRFR, LOCALE_PTBR,} from "./components/Locale";

const fallbackLng = [LOCALE_PTBR];
const defaultNS = "common";
const fallbackNS = defaultNS;
const ns = [defaultNS, "home"];
const lng = getLocale();
const availableLanguages = [
    LOCALE_PTBR,
    LOCALE_ESCO,
    LOCALE_ESMX,
    LOCALE_ENUS,
    LOCALE_FRFR,
];

export const i18nOptions = {
    fallbackLng: fallbackLng,
    //debug: true,
    whitelist: availableLanguages,
    lng: lng,
    nsSeparator: ':',
    ns: ns,
    defaultNS: defaultNS,
    fallbackNS: fallbackNS,
    // language to use
    resources: {
        "pt-BR": ptBRi18namespaces,
        "en-US": enUSi18namespaces,
        "es-CO": esCOi18namespaces,
        "es-MX": esMXi18namespaces,
        "fr-FR": frFRi18namespaces,
    },
};