import React,{createContext, useState, useEffect, useContext} from "react";
import {getLocale, setLocale as setLocaleStorage, getLocaleByCountryId, Locales} from "./Locale";
import i18next from "./../i18next"

export const ShowDataContext = createContext();

export function ShowDataProvider({country, children}) {
    const localestorage = getLocale();
    const [locale, setLocale] = useState(localestorage); //new Intl.Locale("pt-BR");
    const [intlOptions, setIntlOptions] = useState(Locales[locale]); //new Intl.Locale("pt-BR");
    const dateLocale = intlOptions.dateFns; //new Intl.Locale("pt-BR");
    //const intlLocale = new Intl.Locale("pt-BR");
    const intlLocale = new Intl.Locale(intlOptions.locale);
    const intLiters = new Intl.NumberFormat(intlLocale, intlOptions.liters);
    const intKm = new Intl.NumberFormat(intlLocale, intlOptions.km);
    const intlCurrency = new Intl.NumberFormat(
        intlLocale,
        Locales[getLocaleByCountryId(country)].currency
    );
    const intlNumber = new Intl.NumberFormat(intlLocale);
    const currencySymbol = (0)
        .toLocaleString(intlLocale, {
            ...intlOptions.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();

    useEffect(() => {
        if (locale) {
            i18next.changeLanguage(locale);
            setLocaleStorage(locale);
        }
    }, [locale]);

    const changeLocale = (localeStr) => {
        setLocale(localeStr);
        setIntlOptions(Locales[localeStr]);
    };
    return (
        <ShowDataContext.Provider
            value={{
                locale: locale,
                dateLocale: dateLocale,
                intlCurrency: intlCurrency,
                intKm: intKm,
                intLiters: intLiters,
                intlNumber: intlNumber,
                changeLocale: changeLocale,
                currencySymbol,
            }}
        >
            {children}
        </ShowDataContext.Provider>
    );
}

export function useShowDataContext() {
    return useContext(ShowDataContext);
}
