import React, {useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import i18next from "./../i18next";

export const T = ({children, ...props}) => {
    return <Trans i18nKey={children} {...props} />;
};

export function translate(key) {
    return i18next.t(key);
}

export function translateArray(array) {
    return array.map((item) => i18next.t(item));
}

export function useTranslateArray(array) {
    const translatedArray = useMemo(() => {
        return array.map((item) => i18next.t(item));
    }, [array]);
    //const arrayTranslated = useMemo(() => { ));
    return {t: translate, arrayTranslated: translatedArray};
}

export function useTranslationWrapper() {
    const {t, ready} = useTranslation();
    return {t, ready};
}

export const t = (key) => translate(key);
